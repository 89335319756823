import validate from "/tmp/build_bb1c3d1b/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/tmp/build_bb1c3d1b/middleware/redirect.global.ts";
import staging_45auth_45global from "/tmp/build_bb1c3d1b/middleware/stagingAuth.global.ts";
import manifest_45route_45rule from "/tmp/build_bb1c3d1b/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  staging_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/tmp/build_bb1c3d1b/middleware/adminOnly.ts"),
  auth: () => import("/tmp/build_bb1c3d1b/middleware/auth.ts"),
  "dashboard-products": () => import("/tmp/build_bb1c3d1b/middleware/dashboardProducts.ts"),
  "manager-and-admin-only": () => import("/tmp/build_bb1c3d1b/middleware/managerAndAdminOnly.ts")
}