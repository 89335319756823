import { default as _36100_45ouncesaiE2TZGt4AMeta } from "/tmp/build_bb1c3d1b/pages/$100-ounces.vue?macro=true";
import { default as accessibilityHZyui9wJA1Meta } from "/tmp/build_bb1c3d1b/pages/accessibility.vue?macro=true";
import { default as _91slug_937yXwNONMAXMeta } from "/tmp/build_bb1c3d1b/pages/brands/[slug].vue?macro=true";
import { default as build_45a_45packE3hOcNaaSnMeta } from "/tmp/build_bb1c3d1b/pages/build-a-pack.vue?macro=true";
import { default as cannabis_45pricingWUpEXYxatdMeta } from "/tmp/build_bb1c3d1b/pages/cannabis-pricing.vue?macro=true";
import { default as checkoutcLdz8Ib2PvMeta } from "/tmp/build_bb1c3d1b/pages/checkout.vue?macro=true";
import { default as contactyL0am7GEcqMeta } from "/tmp/build_bb1c3d1b/pages/contact.vue?macro=true";
import { default as confirmpfPtD9XRkGMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/confirm.vue?macro=true";
import { default as indexCaFaesWFbxMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/index.vue?macro=true";
import { default as _91id_93uelK3CUUGGMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/jobs/[id].vue?macro=true";
import { default as createua6m7w6fdSMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/jobs/create.vue?macro=true";
import { default as index3RtyAJicBxMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/jobs/index.vue?macro=true";
import { default as loginynbB27WLiwMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/login.vue?macro=true";
import { default as bundle_45image_45buildercTCp3D1isNMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/media/bundle-image-builder.vue?macro=true";
import { default as indexzhPKHSOsBtMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/media/index.vue?macro=true";
import { default as index7Er1H0v19HMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/menu/index.vue?macro=true";
import { default as _91_46_46_46slug_93JiXWL6qmcsMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/pages/[...slug].vue?macro=true";
import { default as homejrKAjaSznlMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/pages/home.vue?macro=true";
import { default as indexp9CWbv3eToMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/pages/index.vue?macro=true";
import { default as _91id_93cDF13oDASjMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/products/[id].vue?macro=true";
import { default as indexbvsstOByOcMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/products/index.vue?macro=true";
import { default as forecastsMIATBPPDqQMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/reporting/forecasts.vue?macro=true";
import { default as _91id_93h2zi6w9KsgMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/reviews/[id].vue?macro=true";
import { default as index1BLei9J160Meta } from "/tmp/build_bb1c3d1b/pages/dashboard/reviews/index.vue?macro=true";
import { default as settingsoEmwAgU1B7Meta } from "/tmp/build_bb1c3d1b/pages/dashboard/settings.vue?macro=true";
import { default as themetHdKHKXj05Meta } from "/tmp/build_bb1c3d1b/pages/dashboard/theme.vue?macro=true";
import { default as _91id_93hPfyVDpaKPMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/users/[id].vue?macro=true";
import { default as index5B5VlPOvh5Meta } from "/tmp/build_bb1c3d1b/pages/dashboard/users/index.vue?macro=true";
import { default as indexO1SiRaBN8jMeta } from "/tmp/build_bb1c3d1b/pages/dashboard/web-vitals/index.vue?macro=true";
import { default as dashboardpW2XPVTpYwMeta } from "/tmp/build_bb1c3d1b/pages/dashboard.vue?macro=true";
import { default as dispensary_45birthday_45deals_45michiganwhxLLtSNqYMeta } from "/tmp/build_bb1c3d1b/pages/dispensary-birthday-deals-michigan.vue?macro=true";
import { default as dispensary_45dealsrFshV0qp5aMeta } from "/tmp/build_bb1c3d1b/pages/dispensary-deals.vue?macro=true";
import { default as first_45time_45dispensary_45dealsWlYpX2yuiHMeta } from "/tmp/build_bb1c3d1b/pages/first-time-dispensary-deals.vue?macro=true";
import { default as glass_45jarssxgN4OpGXIMeta } from "/tmp/build_bb1c3d1b/pages/glass-jars.vue?macro=true";
import { default as grow_45facility_45tourd13OBonI27Meta } from "/tmp/build_bb1c3d1b/pages/grow-facility-tour.vue?macro=true";
import { default as indexQFvMNcddPsMeta } from "/tmp/build_bb1c3d1b/pages/index.vue?macro=true";
import { default as _91slug_933RWi6jx4giMeta } from "/tmp/build_bb1c3d1b/pages/jobs/[slug].vue?macro=true";
import { default as indexKUCR77suU4Meta } from "/tmp/build_bb1c3d1b/pages/jobs/index.vue?macro=true";
import { default as cannabinoidsU8QS9BWtZjMeta } from "/tmp/build_bb1c3d1b/pages/learn/cannabinoids.vue?macro=true";
import { default as cannabis_45anatomywHXxq8IxxsMeta } from "/tmp/build_bb1c3d1b/pages/learn/cannabis-anatomy.vue?macro=true";
import { default as cannabis_45historyKI899aCKhcMeta } from "/tmp/build_bb1c3d1b/pages/learn/cannabis-history.vue?macro=true";
import { default as michigan_45dispensary_45purchase_45limitswhVQMXsHhzMeta } from "/tmp/build_bb1c3d1b/pages/learn/michigan-dispensary-purchase-limits.vue?macro=true";
import { default as terpenes_45over_45potencyvoL0uU3dNYMeta } from "/tmp/build_bb1c3d1b/pages/learn/terpenes-over-potency.vue?macro=true";
import { default as what_45is_45cannabis6gYUTwCOJzMeta } from "/tmp/build_bb1c3d1b/pages/learn/what-is-cannabis.vue?macro=true";
import { default as michigan_45dispensary_45near_45indianakPMkLI40rYMeta } from "/tmp/build_bb1c3d1b/pages/michigan-dispensary-near-indiana.vue?macro=true";
import { default as michigan_45dispensary_45near_45ohiovFz0p0VE8zMeta } from "/tmp/build_bb1c3d1b/pages/michigan-dispensary-near-ohio.vue?macro=true";
import { default as newsletterGiEVnJebzHMeta } from "/tmp/build_bb1c3d1b/pages/newsletter.vue?macro=true";
import { default as order_45trackerrhBFLhBbRpMeta } from "/tmp/build_bb1c3d1b/pages/order-tracker.vue?macro=true";
import { default as privacy_45policydInSwrJZwHMeta } from "/tmp/build_bb1c3d1b/pages/privacy-policy.vue?macro=true";
import { default as _91slug_93qgm4nNUta0Meta } from "/tmp/build_bb1c3d1b/pages/products/[slug].vue?macro=true";
import { default as quick_45orderUYFdMsF20kMeta } from "/tmp/build_bb1c3d1b/pages/quick-order.vue?macro=true";
import { default as refund_45policygmMOcMnRPaMeta } from "/tmp/build_bb1c3d1b/pages/refund-policy.vue?macro=true";
import { default as reviewsf79FdBDCMIMeta } from "/tmp/build_bb1c3d1b/pages/reviews.vue?macro=true";
import { default as staging_45loginG5gne9reW5Meta } from "/tmp/build_bb1c3d1b/pages/staging-login.vue?macro=true";
import { default as strain_45libraryoGOjIBSNAWMeta } from "/tmp/build_bb1c3d1b/pages/strain-library.vue?macro=true";
import { default as terms_45of_45useLGErrS9J6QMeta } from "/tmp/build_bb1c3d1b/pages/terms-of-use.vue?macro=true";
import { default as thank_45youjlILuEP2M4Meta } from "/tmp/build_bb1c3d1b/pages/thank-you.vue?macro=true";
import { default as weedys_45weartXAGOenkaKMeta } from "/tmp/build_bb1c3d1b/pages/weedys-wear.vue?macro=true";
import { default as wholesaleYe1LKAE06UMeta } from "/tmp/build_bb1c3d1b/pages/wholesale.vue?macro=true";
export default [
  {
    name: "$100-ounces",
    path: "/$100-ounces",
    component: () => import("/tmp/build_bb1c3d1b/pages/$100-ounces.vue").then(m => m.default || m)
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/tmp/build_bb1c3d1b/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: "brands-slug",
    path: "/brands/:slug()",
    component: () => import("/tmp/build_bb1c3d1b/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "build-a-pack",
    path: "/build-a-pack",
    component: () => import("/tmp/build_bb1c3d1b/pages/build-a-pack.vue").then(m => m.default || m)
  },
  {
    name: "cannabis-pricing",
    path: "/cannabis-pricing",
    component: () => import("/tmp/build_bb1c3d1b/pages/cannabis-pricing.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/tmp/build_bb1c3d1b/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_bb1c3d1b/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: dashboardpW2XPVTpYwMeta?.name,
    path: "/dashboard",
    meta: dashboardpW2XPVTpYwMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-confirm",
    path: "confirm",
    meta: confirmpfPtD9XRkGMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/confirm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jobs-id",
    path: "jobs/:id()",
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jobs-create",
    path: "jobs/create",
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jobs",
    path: "jobs",
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-login",
    path: "login",
    meta: loginynbB27WLiwMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/login.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-media-bundle-image-builder",
    path: "media/bundle-image-builder",
    meta: bundle_45image_45buildercTCp3D1isNMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/media/bundle-image-builder.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-media",
    path: "media",
    meta: indexzhPKHSOsBtMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/media/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-menu",
    path: "menu",
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages-slug",
    path: "pages/:slug(.*)*",
    meta: _91_46_46_46slug_93JiXWL6qmcsMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages-home",
    path: "pages/home",
    meta: homejrKAjaSznlMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages",
    path: "pages",
    meta: indexp9CWbv3eToMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-products-id",
    path: "products/:id()",
    meta: _91id_93cDF13oDASjMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-products",
    path: "products",
    meta: indexbvsstOByOcMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/products/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-reporting-forecasts",
    path: "reporting/forecasts",
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/reporting/forecasts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-reviews-id",
    path: "reviews/:id()",
    meta: _91id_93h2zi6w9KsgMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/reviews/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-reviews",
    path: "reviews",
    meta: index1BLei9J160Meta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "settings",
    meta: settingsoEmwAgU1B7Meta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-theme",
    path: "theme",
    meta: themetHdKHKXj05Meta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/theme.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users-id",
    path: "users/:id()",
    meta: _91id_93hPfyVDpaKPMeta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "users",
    meta: index5B5VlPOvh5Meta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-web-vitals",
    path: "web-vitals",
    component: () => import("/tmp/build_bb1c3d1b/pages/dashboard/web-vitals/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dispensary-birthday-deals-michigan",
    path: "/dispensary-birthday-deals-michigan",
    component: () => import("/tmp/build_bb1c3d1b/pages/dispensary-birthday-deals-michigan.vue").then(m => m.default || m)
  },
  {
    name: "dispensary-deals",
    path: "/dispensary-deals",
    component: () => import("/tmp/build_bb1c3d1b/pages/dispensary-deals.vue").then(m => m.default || m)
  },
  {
    name: "first-time-dispensary-deals",
    path: "/first-time-dispensary-deals",
    component: () => import("/tmp/build_bb1c3d1b/pages/first-time-dispensary-deals.vue").then(m => m.default || m)
  },
  {
    name: "glass-jars",
    path: "/glass-jars",
    component: () => import("/tmp/build_bb1c3d1b/pages/glass-jars.vue").then(m => m.default || m)
  },
  {
    name: "grow-facility-tour",
    path: "/grow-facility-tour",
    component: () => import("/tmp/build_bb1c3d1b/pages/grow-facility-tour.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_bb1c3d1b/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug",
    path: "/jobs/:slug()",
    component: () => import("/tmp/build_bb1c3d1b/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/tmp/build_bb1c3d1b/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "learn-cannabinoids",
    path: "/learn/cannabinoids",
    component: () => import("/tmp/build_bb1c3d1b/pages/learn/cannabinoids.vue").then(m => m.default || m)
  },
  {
    name: "learn-cannabis-anatomy",
    path: "/learn/cannabis-anatomy",
    component: () => import("/tmp/build_bb1c3d1b/pages/learn/cannabis-anatomy.vue").then(m => m.default || m)
  },
  {
    name: "learn-cannabis-history",
    path: "/learn/cannabis-history",
    component: () => import("/tmp/build_bb1c3d1b/pages/learn/cannabis-history.vue").then(m => m.default || m)
  },
  {
    name: "learn-michigan-dispensary-purchase-limits",
    path: "/learn/michigan-dispensary-purchase-limits",
    component: () => import("/tmp/build_bb1c3d1b/pages/learn/michigan-dispensary-purchase-limits.vue").then(m => m.default || m)
  },
  {
    name: "learn-terpenes-over-potency",
    path: "/learn/terpenes-over-potency",
    component: () => import("/tmp/build_bb1c3d1b/pages/learn/terpenes-over-potency.vue").then(m => m.default || m)
  },
  {
    name: "learn-what-is-cannabis",
    path: "/learn/what-is-cannabis",
    component: () => import("/tmp/build_bb1c3d1b/pages/learn/what-is-cannabis.vue").then(m => m.default || m)
  },
  {
    name: "michigan-dispensary-near-indiana",
    path: "/michigan-dispensary-near-indiana",
    component: () => import("/tmp/build_bb1c3d1b/pages/michigan-dispensary-near-indiana.vue").then(m => m.default || m)
  },
  {
    name: "michigan-dispensary-near-ohio",
    path: "/michigan-dispensary-near-ohio",
    component: () => import("/tmp/build_bb1c3d1b/pages/michigan-dispensary-near-ohio.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/tmp/build_bb1c3d1b/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "order-tracker",
    path: "/order-tracker",
    component: () => import("/tmp/build_bb1c3d1b/pages/order-tracker.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/tmp/build_bb1c3d1b/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/tmp/build_bb1c3d1b/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "quick-order",
    path: "/quick-order",
    component: () => import("/tmp/build_bb1c3d1b/pages/quick-order.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy",
    path: "/refund-policy",
    component: () => import("/tmp/build_bb1c3d1b/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/tmp/build_bb1c3d1b/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "staging-login",
    path: "/staging-login",
    meta: staging_45loginG5gne9reW5Meta || {},
    component: () => import("/tmp/build_bb1c3d1b/pages/staging-login.vue").then(m => m.default || m)
  },
  {
    name: "strain-library",
    path: "/strain-library",
    component: () => import("/tmp/build_bb1c3d1b/pages/strain-library.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/tmp/build_bb1c3d1b/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/tmp/build_bb1c3d1b/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "weedys-wear",
    path: "/weedys-wear",
    component: () => import("/tmp/build_bb1c3d1b/pages/weedys-wear.vue").then(m => m.default || m)
  },
  {
    name: "wholesale",
    path: "/wholesale",
    component: () => import("/tmp/build_bb1c3d1b/pages/wholesale.vue").then(m => m.default || m)
  }
]