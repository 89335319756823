<template>
	<button
		:type="type"
		:disabled="disabled"
		class="h-8 min-w-[100px] rounded-3xl border-none px-5 text-sm font-medium text-white transition duration-150 ease-in-out"
		:class="[colors, size]"
	>
		<slot></slot>
	</button>
</template>

<script setup lang="ts">
interface Props {
	disabled?: boolean;
	primaryColor?: boolean;
	type?: 'button' | 'submit' | 'reset' | undefined;
	xl?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
	primaryColor: false,
	type: 'button',
	xl: false,
});

const colors = computed(() => {
	if (props.primaryColor) {
		return 'bg-cta hover:bg-cta-hover';
	}
	return 'bg-black hover:bg-slate-800';
});

const size = computed(() => {
	if (props.xl) {
		return 'md:h-12 md:text-xl !px-10';
	}
	return 'md:h-9 md:text-base';
});
</script>
